import { DownloadIcon } from '@heroicons/react/solid';
import { observer } from 'mobx-react';
import { useSession } from 'next-auth/react';
import { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';

import OutOfStockProduct from '@/compoments/products/OutOfStock';
import { prettyDate } from '@/compoments/utils/dates';
import DownloadInputs from '@/compoments/utils/DownloadInputs';
import { Meta } from '@/layouts/Meta';
import type { ProductVariantMemoized } from '@/models/product';
import type { PurchaseMemoized } from '@/models/purchase';
import {
  useCategoryStore,
  useProductStore,
  useProductVariantStore,
  usePurchaseStore,
  useSaleStatisticsStore,
  useSaleStore,
} from '@/providers/RootStoreProvider';
import { Main } from '@/templates/Main';

interface SheetColumnHeader<T> {
  label: string;
  getter: (item: T, row: number) => XLSX.CellObject;
}

const createSheet = <T,>(
  header: string,
  columns: SheetColumnHeader<T>[],
  data: T[]
) => {
  const worksheet = XLSX.utils.aoa_to_sheet([[header]]);
  if (!worksheet['!merges']) worksheet['!merges'] = [];

  const current_cell: XLSX.CellAddress = {
    c: 0,
    r: 0,
  };
  const header_range: XLSX.Range = {
    s: { ...current_cell },
    e: {
      c: columns.length - 1,
      r: 0,
    },
  };
  worksheet['!merges'].push(header_range); // merge A1:B3
  current_cell.r = 2;
  XLSX.utils.sheet_add_aoa(worksheet, [columns.map((c) => c.label)], {
    origin: current_cell,
  });
  data.forEach((item) => {
    current_cell.r += 1;
    const c_data = columns.map((column) => column.getter(item, current_cell.r));
    XLSX.utils.sheet_add_aoa(worksheet, [c_data], { origin: current_cell });
  });
  return worksheet;
};

interface ExportableProduct {
  date: string;
  invoice: string;
  discount: number;
  quantity: number;
  unit_price_htva: number;
  tva: number;
  display_name: string;
}

interface Fees {
  date: string;
  invoice: string;
  price: number;
}

const Index = observer(() => {
  useSession({ required: true });
  const saleStatisticsStore = useSaleStatisticsStore();
  const productStore = useProductStore();
  const productVariantStore = useProductVariantStore();
  const purchaseStore = usePurchaseStore();
  const categoryStore = useCategoryStore();
  const salesStore = useSaleStore();

  const [downloadModal, setDownloadModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      await saleStatisticsStore.listObjects();
      await productStore.listObjects();
      await productVariantStore.listObjects();
      await purchaseStore.listObjects();
      await categoryStore.listObjects();
      await salesStore.listObjects();
    };
    fetchData();
  }, []);

  const { entries: cards } = saleStatisticsStore;
  const { map: products_map } = productStore;
  const { entriesMemoized: product_variants, map: product_variants_map } =
    productVariantStore;
  const { entriesMemoized: purchases } = purchaseStore;
  const { entriesMemoized: categories } = categoryStore;
  const { entriesMemoized: sales } = salesStore;
  const { mapMemoized: productVariants } = productVariantStore;

  const product_sales: ExportableProduct[] = [];
  const platform_fees: Fees[] = [];
  const delivery_fees: Fees[] = [];
  sales.forEach((s) => {
    let sale_products: ExportableProduct[] = [];
    const meta_row = {
      date: s.date,
      invoice: s.note,
      discount: 0.0,
    };
    s.boxes.forEach((b) => {
      b.products.forEach((p) => {
        const { quantity, unit_price_htva } = p;
        if (quantity === 0) {
          return;
        }
        const v = productVariants[p.product];
        if (!v) return;
        const { tva, display_name } = v;
        sale_products.push({
          ...meta_row,
          quantity,
          unit_price_htva,
          tva,
          display_name,
        });
      });
    });
    s.products.forEach((p) => {
      const { quantity, unit_price_htva } = p;
      if (quantity === 0) {
        return;
      }
      const v = productVariants[p.product];
      if (!v) return;
      const { tva, display_name } = v;
      sale_products.push({
        ...meta_row,
        quantity,
        unit_price_htva,
        tva,
        display_name,
      });
    });
    const num_products = sale_products.length;
    if (s.delivery_cost) {
      sale_products.push({
        ...meta_row,
        quantity: 1,
        unit_price_htva: s.delivery_cost,
        tva: 0,
        display_name: 'livraison',
      });
      delivery_fees.push({
        date: s.date,
        invoice: s.note,
        price: s.delivery_cost,
      });
    }
    if (s.sale_platform_fee) {
      platform_fees.push({
        date: s.date,
        invoice: s.note,
        price: s.sale_platform_fee,
      });
    }
    if (s.discount_fixed > 0 && num_products > 0) {
      const product_discount = s.discount_fixed / num_products;
      sale_products = sale_products.map((sp) => {
        const nsp = { ...sp };
        nsp.discount = product_discount / nsp.quantity;
        return nsp;
      });
    } else if (s.discount_percent > 0 && num_products > 0) {
      const discount = s.discount_percent / 100;
      sale_products = sale_products.map((sp) => {
        const nsp = { ...sp };
        nsp.discount = discount * nsp.unit_price_htva;
        return nsp;
      });
    }
    product_sales.push(...sale_products);
  });

  return (
    <Main meta={<Meta title="Accueil" description="Accueil" />}>
      <DownloadInputs
        show={downloadModal}
        callback={async (show, from_date, to_date) => {
          if (!from_date || !to_date) return;
          if (!show) {
            setDownloadModal(false);
          } else {
            // const data = await downloadData(from_date, to_date);
            // const purchase_sheet = XLSX.utils.sheet_n
            // const worksheet = XLSX.utils.json_to_sheet(data);
            const workbook = XLSX.utils.book_new();
            categories.forEach((category) => {
              const name_upper = category.name.toUpperCase();
              const inventory_worksheet = createSheet(
                `INVENTAIRE ${name_upper}`,
                [
                  {
                    label: 'ARTICLES',
                    getter: (p: ProductVariantMemoized) => ({
                      v: p.display_name,
                      t: 's',
                    }),
                  },
                  {
                    label: 'TAUX TVA',
                    getter: (i: ProductVariantMemoized) => ({
                      v: (i.tva || 0) / 100,
                      t: 'n',
                      z: '0.00%',
                    }),
                  },
                  {
                    label: 'PU HTVA',
                    getter: (i: ProductVariantMemoized) => ({
                      v: i.buying_price_htva,
                      t: 'n',
                      z: '#,##0.00 "€ "',
                    }),
                  },
                  {
                    label: 'PU TVA',
                    getter: (_, r) => {
                      const pu_address: XLSX.CellAddress = { c: 1, r };
                      const pu_address_range =
                        XLSX.utils.encode_cell(pu_address);
                      const tva_address: XLSX.CellAddress = { c: 2, r };
                      const tva_address_range =
                        XLSX.utils.encode_cell(tva_address);
                      return {
                        f: `${pu_address_range}*${tva_address_range}`,
                        t: 'n',
                        z: '#,##0.00 "€ "',
                      };
                    },
                  },
                  {
                    label: 'PU TVAC',
                    getter: (_, r) => {
                      const pu_address: XLSX.CellAddress = { c: 2, r };
                      const pu_address_range =
                        XLSX.utils.encode_cell(pu_address);
                      const tva_address: XLSX.CellAddress = { c: 3, r };
                      const tva_address_range =
                        XLSX.utils.encode_cell(tva_address);
                      return {
                        f: `${pu_address_range}+${tva_address_range}`,
                        t: 'n',
                        z: '#,##0.00 "€ "',
                      };
                    },
                  },
                  {
                    label: 'PV HTVA',
                    getter: (i: ProductVariantMemoized) => ({
                      v: i.selling_price_htva,
                      t: 'n',
                      z: '#,##0.00 "€ "',
                    }),
                  },
                  {
                    label: 'PV TVA',
                    getter: (_, r) => {
                      const pu_address: XLSX.CellAddress = { c: 5, r };
                      const pu_address_range =
                        XLSX.utils.encode_cell(pu_address);
                      const tva_address: XLSX.CellAddress = { c: 1, r };
                      const tva_address_range =
                        XLSX.utils.encode_cell(tva_address);
                      return {
                        f: `${pu_address_range}*${tva_address_range}`,
                        t: 'n',
                        z: '#,##0.00 "€ "',
                      };
                    },
                  },
                  {
                    label: 'PV TVAC',
                    getter: (_, r) => {
                      const pu_address: XLSX.CellAddress = { c: 5, r };
                      const pu_address_range =
                        XLSX.utils.encode_cell(pu_address);
                      const tva_address: XLSX.CellAddress = { c: 6, r };
                      const tva_address_range =
                        XLSX.utils.encode_cell(tva_address);
                      return {
                        f: `${pu_address_range}+${tva_address_range}`,
                        t: 'n',
                        z: '#,##0.00 "€ "',
                      };
                    },
                  },
                  {
                    label: 'Marge HTVA',
                    getter: (_, r) => {
                      const pu_htva_address: XLSX.CellAddress = { c: 2, r };
                      const pu_htva_address_range =
                        XLSX.utils.encode_cell(pu_htva_address);
                      const pv_htva_address: XLSX.CellAddress = { c: 5, r };
                      const pv_htva_address_range =
                        XLSX.utils.encode_cell(pv_htva_address);
                      return {
                        f: `${pv_htva_address_range}-${pu_htva_address_range}`,
                        t: 'n',
                        z: '#,##0.00 "€ "',
                      };
                    },
                  },
                  {
                    label: 'Marge TVAC',
                    getter: (_, r) => {
                      const pu_htva_address: XLSX.CellAddress = { c: 4, r };
                      const pu_htva_address_range =
                        XLSX.utils.encode_cell(pu_htva_address);
                      const pv_htva_address: XLSX.CellAddress = { c: 7, r };
                      const pv_htva_address_range =
                        XLSX.utils.encode_cell(pv_htva_address);
                      return {
                        f: `${pv_htva_address_range}-${pu_htva_address_range}`,
                        t: 'n',
                        z: '#,##0.00 "€ "',
                      };
                    },
                  },
                  {
                    label: 'Quantité',
                    getter: (p: ProductVariantMemoized) => {
                      return { v: p.stock, t: 'n' };
                    },
                  },
                ],
                product_variants.filter((pv) => {
                  const p = products_map[pv.product];
                  if (!p) return false;
                  return p.category === category.id;
                })
              );
              XLSX.utils.book_append_sheet(
                workbook,
                inventory_worksheet,
                `INVENTAIRE ${name_upper}`
              );
            });
            categories.forEach((category) => {
              const name_upper = category.name.toUpperCase();

              const purchase_worksheet = createSheet(
                `ACHATS ${name_upper} -- ${prettyDate(
                  from_date
                )} - ${prettyDate(to_date)}`,
                [
                  {
                    label: 'DATE',
                    getter: (i: PurchaseMemoized) => ({ v: i.date, t: 'd' }),
                  },
                  {
                    label: 'ARTICLES',
                    getter: (i: PurchaseMemoized) => ({
                      v: i.product_name,
                      t: 's',
                    }),
                  },
                  {
                    label: 'FACTURE',
                    getter: (i: PurchaseMemoized) => ({ v: i.notes, t: 's' }),
                  },
                  {
                    label: 'PU HTVA',
                    getter: (i: PurchaseMemoized) => ({
                      v: i.unit_price_htva,
                      t: 'n',
                      z: '#,##0.00 "€ "',
                    }),
                  },
                  {
                    label: 'TAUX REMISE',
                    getter: (i: PurchaseMemoized) => ({
                      v: i.discount / 100,
                      t: 'n',
                      z: '0.00%',
                    }),
                  },
                  {
                    label: 'PU HTVA',
                    getter: (_, r) => {
                      const pu_address: XLSX.CellAddress = { c: 3, r };
                      const pu_address_range =
                        XLSX.utils.encode_cell(pu_address);
                      const discount_address: XLSX.CellAddress = { c: 4, r };
                      const discount_address_range =
                        XLSX.utils.encode_cell(discount_address);
                      return {
                        f: `${pu_address_range}*(1-${discount_address_range})`,
                        t: 'n',
                        z: '#,##0.00 "€ "',
                      };
                    },
                  },
                  {
                    label: 'TAUX TVA',
                    getter: (i: PurchaseMemoized) => ({
                      v: (i.product_tva || 0) / 100,
                      t: 'n',
                      z: '0.00%',
                    }),
                  },
                  {
                    label: 'TVA',
                    getter: (_, r) => {
                      const pu_address: XLSX.CellAddress = { c: 5, r };
                      const pu_address_range =
                        XLSX.utils.encode_cell(pu_address);
                      const tva_address: XLSX.CellAddress = { c: 6, r };
                      const tva_address_range =
                        XLSX.utils.encode_cell(tva_address);
                      return {
                        f: `${pu_address_range}*${tva_address_range}`,
                        t: 'n',
                        z: '#,##0.00 "€ "',
                      };
                    },
                  },
                  {
                    label: 'PU TVAC',
                    getter: (_, r) => {
                      const pu_address: XLSX.CellAddress = { c: 5, r };
                      const pu_address_range =
                        XLSX.utils.encode_cell(pu_address);
                      const pu_tva_address: XLSX.CellAddress = { c: 7, r };
                      const pu_tva_address_range =
                        XLSX.utils.encode_cell(pu_tva_address);
                      return {
                        f: `${pu_address_range}+${pu_tva_address_range}`,
                        t: 'n',
                        z: '#,##0.00 "€ "',
                      };
                    },
                  },
                  {
                    label: 'QTE',
                    getter: (i: PurchaseMemoized) => {
                      return { v: i.quantity, t: 'n' };
                    },
                  },
                  {
                    label: 'TOTAL HTVA',
                    getter: (_, r: number) => {
                      const pu_address: XLSX.CellAddress = { c: 5, r };
                      const pu_address_range =
                        XLSX.utils.encode_cell(pu_address);
                      const quantity_address: XLSX.CellAddress = { c: 9, r };
                      const pu_tva_address_range =
                        XLSX.utils.encode_cell(quantity_address);
                      return {
                        f: `${pu_address_range}*${pu_tva_address_range}`,
                        t: 'n',
                        z: '#,##0.00 "€ "',
                      };
                    },
                  },
                  {
                    label: 'TOTAL TVAC',
                    getter: (_, r: number) => {
                      const pu_address: XLSX.CellAddress = { c: 8, r };
                      const pu_address_range =
                        XLSX.utils.encode_cell(pu_address);
                      const quantity_address: XLSX.CellAddress = { c: 9, r };
                      const pu_tva_address_range =
                        XLSX.utils.encode_cell(quantity_address);
                      return {
                        f: `${pu_address_range}*${pu_tva_address_range}`,
                        t: 'n',
                        z: '#,##0.00 "€ "',
                      };
                    },
                  },
                ],
                purchases.filter((p) => {
                  const product_variant = product_variants_map[p.product];
                  if (!product_variant) return false;
                  const product = products_map[product_variant.product];
                  if (!product) return false;
                  if (product.category !== category.id) return false;
                  const d = new Date(p.date);
                  return d >= from_date && d <= to_date;
                })
              );
              XLSX.utils.book_append_sheet(
                workbook,
                purchase_worksheet,
                `ACHATS ${name_upper}`
              );
            });
            const sale_worksheet = createSheet(
              `VENTES -- ${prettyDate(from_date)} - ${prettyDate(to_date)}`,
              [
                {
                  label: 'DATE',
                  getter: (i: ExportableProduct) => ({ v: i.date, t: 'd' }),
                },
                {
                  label: 'ARTICLES',
                  getter: (i: ExportableProduct) => ({
                    v: i.display_name,
                    t: 's',
                  }),
                },
                {
                  label: 'FACTURE',
                  getter: (i: ExportableProduct) => ({ v: i.invoice, t: 's' }),
                },
                {
                  label: 'PV HTVA',
                  getter: (i: ExportableProduct) => ({
                    v: i.unit_price_htva,
                    t: 'n',
                    z: '#,##0.00 "€ "',
                  }),
                },
                {
                  label: 'TAUX REMISE',
                  getter: (i: ExportableProduct) => ({
                    v: i.discount,
                    t: 'n',
                    z: '#,##0.00 "€ "',
                  }),
                },
                {
                  label: 'PU HTVA',
                  getter: (_, r) => {
                    const pu_address: XLSX.CellAddress = { c: 3, r };
                    const pu_address_range = XLSX.utils.encode_cell(pu_address);
                    const discount_address: XLSX.CellAddress = { c: 4, r };
                    const discount_address_range =
                      XLSX.utils.encode_cell(discount_address);
                    return {
                      f: `${pu_address_range}-${discount_address_range}`,
                      t: 'n',
                      z: '#,##0.00 "€ "',
                    };
                  },
                },
                {
                  label: 'TAUX TVA',
                  getter: (i: ExportableProduct) => ({
                    v: (i.tva || 0) / 100,
                    t: 'n',
                    z: '0.00%',
                  }),
                },
                {
                  label: 'TVA',
                  getter: (_, r) => {
                    const pu_address: XLSX.CellAddress = { c: 5, r };
                    const pu_address_range = XLSX.utils.encode_cell(pu_address);
                    const tva_address: XLSX.CellAddress = { c: 6, r };
                    const tva_address_range =
                      XLSX.utils.encode_cell(tva_address);
                    return {
                      f: `${pu_address_range}*${tva_address_range}`,
                      t: 'n',
                      z: '#,##0.00 "€ "',
                    };
                  },
                },
                {
                  label: 'PU TVAC',
                  getter: (_, r) => {
                    const pu_address: XLSX.CellAddress = { c: 5, r };
                    const pu_address_range = XLSX.utils.encode_cell(pu_address);
                    const pu_tva_address: XLSX.CellAddress = { c: 7, r };
                    const pu_tva_address_range =
                      XLSX.utils.encode_cell(pu_tva_address);
                    return {
                      f: `${pu_address_range}+${pu_tva_address_range}`,
                      t: 'n',
                      z: '#,##0.00 "€ "',
                    };
                  },
                },
                {
                  label: 'QTE',
                  getter: (i: ExportableProduct) => {
                    return { v: i.quantity, t: 'n' };
                  },
                },
                {
                  label: 'TOTAL HTVA',
                  getter: (_, r: number) => {
                    const pu_address: XLSX.CellAddress = { c: 5, r };
                    const pu_address_range = XLSX.utils.encode_cell(pu_address);
                    const quantity_address: XLSX.CellAddress = { c: 9, r };
                    const pu_tva_address_range =
                      XLSX.utils.encode_cell(quantity_address);
                    return {
                      f: `${pu_address_range}*${pu_tva_address_range}`,
                      t: 'n',
                      z: '#,##0.00 "€ "',
                    };
                  },
                },
                {
                  label: 'TOTAL TVAC',
                  getter: (_, r: number) => {
                    const pu_address: XLSX.CellAddress = { c: 8, r };
                    const pu_address_range = XLSX.utils.encode_cell(pu_address);
                    const quantity_address: XLSX.CellAddress = { c: 9, r };
                    const pu_tva_address_range =
                      XLSX.utils.encode_cell(quantity_address);
                    return {
                      f: `${pu_address_range}*${pu_tva_address_range}`,
                      t: 'n',
                      z: '#,##0.00 "€ "',
                    };
                  },
                },
              ],
              product_sales.filter((s) => {
                const d = new Date(s.date);
                return d >= from_date && d <= to_date;
              })
            );
            XLSX.utils.book_append_sheet(workbook, sale_worksheet, 'VENTES');
            const delivery_fees_worksheet = createSheet(
              `FRAIS DE LIVRAISON -- ${prettyDate(from_date)} - ${prettyDate(
                to_date
              )}`,
              [
                {
                  label: 'DATE',
                  getter: (i: Fees) => ({ v: i.date, t: 'd' }),
                },
                {
                  label: 'FACTURE',
                  getter: (i: Fees) => ({ v: i.invoice, t: 's' }),
                },
                {
                  label: 'PRIX',
                  getter: (i: Fees) => ({
                    v: i.price,
                    t: 'n',
                    z: '#,##0.00 "€ "',
                  }),
                },
              ],
              delivery_fees.filter((s) => {
                const d = new Date(s.date);
                return d >= from_date && d <= to_date;
              })
            );
            XLSX.utils.book_append_sheet(
              workbook,
              delivery_fees_worksheet,
              'FRAIS DE LIVRAISON'
            );
            const platform_fees_worksheet = createSheet(
              `FRAIS DE PLATEFORME -- ${prettyDate(from_date)} - ${prettyDate(
                to_date
              )}`,
              [
                {
                  label: 'DATE',
                  getter: (i: Fees) => ({ v: i.date, t: 'd' }),
                },
                {
                  label: 'FACTURE',
                  getter: (i: Fees) => ({ v: i.invoice, t: 's' }),
                },
                {
                  label: 'PRIX',
                  getter: (i: Fees) => ({
                    v: i.price,
                    t: 'n',
                    z: '#,##0.00 "€ "',
                  }),
                },
              ],
              platform_fees.filter((s) => {
                const d = new Date(s.date);
                return d >= from_date && d <= to_date;
              })
            );
            XLSX.utils.book_append_sheet(
              workbook,
              platform_fees_worksheet,
              'FRAIS DE PLATEFORME'
            );
            XLSX.writeFile(workbook, `bilan_comptable.xlsx`, {
              compression: true,
              cellStyles: true,
            });
          }
        }}
        title={'canevas comptable'}
        downloadWithoutDate={false}
      />
      <div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
        <h2 className="text-lg font-medium leading-6 text-gray-900">Apercu</h2>
        <div className="mt-2 grid grid-cols-1 gap-5 md:grid-cols-3">
          {/* Card */}
          {cards
            .filter((c) => c && c.name && c.name.indexOf('TVAC') <= 0)
            .map((card) => (
              <div
                key={card.name}
                className="max-w-sm rounded-lg border border-gray-200 bg-white p-6 shadow-md"
              >
                <h6 className="text-base font-medium leading-6 text-gray-900">
                  {card.name}
                </h6>
                <p className="mb-3 font-normal text-gray-500">
                  {card.amount.toFixed(2)} €
                </p>
              </div>
            ))}
        </div>
        <div className="my-5 mx-auto">
          <button
            type="button"
            onClick={() => setDownloadModal(true)}
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-orange-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 sm:w-auto"
          >
            <DownloadIcon
              className="-ml-1 mr-2 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            <span>Télécharger cannevas comptable</span>
          </button>
        </div>
        <h2 className="mt-2 text-lg font-medium leading-6 text-gray-900">
          Produits manquants ou faibles en stock
        </h2>
        <div className="mt-2">
          <OutOfStockProduct
            variants={product_variants
              .filter((p) => {
                return p.stock <= 0;
              })
              .sort((a, b) => {
                return a.stock - b.stock;
              })}
          />
        </div>
      </div>
    </Main>
  );
});

export default Index;
