import { observer } from 'mobx-react';

import type { ProductVariantMemoized } from '@/models/product';
import { classNames } from '@/utils/classNames';

type IOutOfStockProductProps = {
  variants: ProductVariantMemoized[];
};

const statusStyles = (stock_level: number): string => {
  if (stock_level === 0) {
    return 'bg-yellow-100 text-yellow-800';
  }
  if (stock_level < 0) {
    return 'bg-red-100 text-red-800';
  }
  return 'bg-green-100 text-green-800';
};

const OutOfStockProduct = observer(({ variants }: IOutOfStockProductProps) => {
  return (
    <div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
      <div className="mt-2 flex flex-col">
        <div className="min-w-full align-middle shadow sm:rounded-lg">
          <table className="block h-60 max-h-full  min-w-full divide-y divide-gray-200   overflow-y-auto">
            <thead>
              <tr>
                <th
                  className="bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                  scope="col"
                >
                  Produit
                </th>
                <th
                  className="bg-gray-50 px-6 py-3 text-right text-sm font-semibold text-gray-900"
                  scope="col"
                >
                  Stock
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {variants.map((variant) => (
                <tr key={variant.id} className="bg-white">
                  <td className="w-full max-w-0 whitespace-nowrap px-6 py-4 text-sm text-gray-900">
                    <div className="flex">
                      <p className="truncate text-gray-500 group-hover:text-gray-900">
                        {variant.display_name}
                      </p>
                    </div>
                  </td>
                  <td className="hidden whitespace-nowrap px-6 py-4 text-sm text-gray-500 md:block">
                    <span
                      className={classNames(
                        statusStyles(variant.stock),
                        'inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium capitalize'
                      )}
                    >
                      {variant.stock}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
});

export default OutOfStockProduct;
